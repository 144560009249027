<template>
<!--各ブログや連絡先 -->
  <div>
    Links<br>
    それぞれのブログ、販売サイトを記載予定。しばしお待ちを。
  </div>
</template>

<script>
  export default {
  name: 'link',
  data: () => ({
    //
  })
}
</script>
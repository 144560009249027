<template>
<!--オタク語りの会・博ふぇすについて -->
  <div>
    Event<br>
    不定期イベントの「オタク語る」について記載する予定です。しばしお待ちを。
  </div>
</template>

<script>
  export default {
  name: 'event',
  data: () => ({
    //
  })
}
</script>

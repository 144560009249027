<template>
<!--オタク語りについての詳しい情報を書く -->
  <div>
    About<br>
    オタク語りについて詳しい情報を記載する予定です。しばしお待ちを。
  </div>
</template>

<script>
  export default {
  name: 'about',
  data: () => ({
    //
  })
}
</script>
